import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AlertService, AuthService, LoaderService, StorageService } from '@services';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate
{
    constructor(
        private router: Router,
        private authService: AuthService,
        private alertService: AlertService,
        private loaderService: LoaderService,
    )
    {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean
    {
        if (!environment.production && !environment.verifyAuth) // only for testing purpose
        {
            this.authService.authStatusHasChanged(true);

            return true;
        }

        return this.isTokenValid();
    }

    private async isTokenValid(): Promise<boolean>
    {
        return new Promise(async (resolve, reject) =>
        {
            const token = StorageService.getUserToken();

            if (!token)
            {
                await this.logoutAndNavigateToWelcomePage(false);

                return reject(false);
            }

            try
            {
                this.loaderService.show();
                const accessGranted = await this.authService.verify();

                if (accessGranted) // token verified, access granted
                {
                    this.authService.authStatusHasChanged(true);

                    return resolve(true);
                }

                // error when verify so redirect to login page
                await this.logoutAndNavigateToWelcomePage();

                return reject(false);
            }
            catch (e)
            {
                console.log('Error in canActivate in AuthGuard', e);
                await this.logoutAndNavigateToWelcomePage();

                return reject(false);
            }
            finally
            {
                this.loaderService.hide();
            }
        });
    }

    private async logoutAndNavigateToWelcomePage(showMessage = true): Promise<void>
    {
        if (showMessage)
        {
            await this.alertService.show('Re-login please.');
        }

        StorageService.signOut();
        await this.router.navigate(['auth']);
    }
}
