import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';

import { ProductBrandModel } from '@models';
import { GetQueryParamsService } from './get-query-params.service';

@Injectable({
    providedIn: 'root'
})
export class ProductBrandService
{
    private endPoint = '/productBrands';

    constructor(
        private http: HttpClient,
    )
    {
    }

    get(filters: { key: string, value: any }[]): Observable<{ total_count: number, items: ProductBrandModel[] }>
    {
        const params = GetQueryParamsService.getQueryParams(filters);

        return this.http.get<{ total_count: number, items: ProductBrandModel[] }>(this.endPoint, {
            params,
        });
    }

    delete(id: number): Promise<{ status: boolean, message: string }>
    {
        const url = `${this.endPoint}/${id}`;

        return firstValueFrom(this.http.delete<{ status: boolean, message: string }>(url));
    }
}
