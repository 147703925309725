import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';

import { CreateSellerModel, SellerModel, UpdateSellerPasswordModel } from '@models';
import { GetQueryParamsService } from './get-query-params.service';

@Injectable({
    providedIn: 'root'
})
export class SellerService
{
    private endPoint = '/sellers';

    constructor(
        private http: HttpClient,
    )
    {
    }

    get(filters: { key: string, value: any }[]): Observable<{ total_count: number, items: SellerModel[] }>
    {
        const params = GetQueryParamsService.getQueryParams(filters);

        return this.http.get<{ total_count: number, items: SellerModel[] }>(this.endPoint, {
            params,
        });
    }

    create(seller: CreateSellerModel): Promise<{ status: boolean, message: string, data: SellerModel }>
    {
        return firstValueFrom(this.http.post<{ status: boolean, message: string, data: SellerModel }>(this.endPoint, seller));
    }

    update(seller: SellerModel): Promise<{ status: boolean, message: string, data: SellerModel }>
    {
        const url = `${this.endPoint}/${seller.id}`;

        return firstValueFrom(this.http.put<{ status: boolean, message: string, data: SellerModel }>(url, seller));
    }

    delete(id: number): Promise<{ status: boolean, message: string }>
    {
        const url = `${this.endPoint}/${id}`;

        return firstValueFrom(this.http.delete<{ status: boolean, message: string }>(url));
    }

    updatePassword(id: number, data: UpdateSellerPasswordModel): Promise<{ status: boolean, message: string, }>
    {
        const url = `${this.endPoint}/${id}/updatePassword`;

        return firstValueFrom(this.http.put<{ status: boolean, message: string, }>(url, data));
    }
}
