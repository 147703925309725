import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { saveAs } from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class FileDownloadService
{
    constructor(
        private http: HttpClient,
    )
    {
    }

    downloadFile(url: string): Observable<Blob>
    {
        return this.http
            .get(url, {
                observe: 'response',
                responseType: 'blob' as 'json',
            })
            .pipe(
                map((response) =>
                {
                    const blob = response.body as Blob;
                    saveAs(blob, this.getFilenameFromContentDispositionHeader(response.headers.get('Content-Disposition')));

                    return blob;
                }),
            );
    }

    private getFilenameFromContentDispositionHeader(headerContent: string | null): string
    {
        if (headerContent !== null && headerContent.indexOf('attachment') !== -1)
        {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(headerContent);

            if (matches !== null && matches[1])
            {
                return matches[1].replace(/['"]/g, '');
            }
        }

        return 'data_export_' + new Date();
    }
}
