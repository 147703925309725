import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { StorageService } from '@services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        const token = StorageService.getUserToken();

        if (!token)
        {
            return next.handle(req.clone());
        }

        let headers = req.headers;

        if (!headers.has('Authorization'))
        {
            headers = headers.append('Authorization', `Bearer ${token}`);
        }

        return next.handle(req.clone({
            headers,
        }));
    }
}
