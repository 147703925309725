import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AlertService
{
    private config: MatSnackBarConfig = {
        duration: 6000,
        horizontalPosition: 'end',
        verticalPosition: 'top',
    };

    constructor(
        private matSnackBar: MatSnackBar,
    )
    {
    }

    show(message: string, action = 'OK')
    {
        this.matSnackBar.open(message, action, this.config);
    }

    showResponseError(response: any)
    {
        if (response instanceof HttpErrorResponse)
        {
            const errorMessage = response.error.errors[Object.keys(response.error.errors)[0]];

            this.show(errorMessage);
        }
    }
}
