import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Injectable({
    providedIn: 'root'
})
export class GetQueryParamsService
{
    static getQueryParams(filters: { key: string, value: any }[]): HttpParams
    {
        let params = new HttpParams();

        for (const filter of filters)
        {
            switch (true)
            {
                case filter.value instanceof MatSort:
                    params = params.append('activeSort', filter.value.active);
                    params = params.append('sortDirection', filter.value.direction);

                    break;
                case filter.value instanceof MatPaginator:
                    params = params.append('page', filter.value.pageIndex + 1);
                    params = params.append('pageSize', filter.value.pageSize);

                    break;
                case filter.value === undefined:
                case filter.value === null:
                case filter.value === '':
                    break;
                default:
                    params = params.append(filter.key, filter.value);

                    break;
            }
        }

        return params;
    }
}
