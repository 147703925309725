import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, finalize, map, retryWhen, tap } from 'rxjs/operators';

import { AlertService } from '@app/services';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor
{
    private maxRetryCount = 3;
    private retryCount = 0;
    private pendingRequests: string[] = [];

    constructor(
        private alertService: AlertService,
    )
    {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        const url = req.url;

        if (this.pendingRequests.includes(url))
        {
            return throwError(() => new Error('Duplicate request stopped.'));
        }

        this.pendingRequests.push(url);

        return next.handle(req.clone())
            .pipe( // retry only if status starts with 5
                retryWhen((errors) => errors
                    .pipe(
                        tap((response) =>
                        {
                            if (response instanceof HttpErrorResponse && !response.status.toString().startsWith('5'))
                            {
                                throw response;
                            }

                            this.retryCount++;

                            if (this.retryCount >= this.maxRetryCount)
                            {
                                this.retryCount = 0;
                                this.alertService.show('Service currently unavailable, please close the app and try again.');

                                throw response;
                            }
                        }),
                        delay(1000),
                    )),
                map((response) =>
                {
                    if (response instanceof HttpResponse)
                    {
                        if (response.body.hasOwnProperty('message'))
                        {
                            this.alertService.show(response.body.message);
                        }
                    }

                    return response;
                }),
                catchError((error) =>
                {
                    this.alertService.showResponseError(error);

                    return throwError(error);
                }),
                finalize(() =>
                {
                    this.retryCount = 0;
                    this.pendingRequests = this.pendingRequests.filter((x) => !x.includes(url));
                }),
            );
    }
}
