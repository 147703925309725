import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';

import { CreateProductModel, ProductModel } from '@models';
import { GetQueryParamsService } from './get-query-params.service';

@Injectable({
    providedIn: 'root'
})
export class ProductService
{
    private endPoint = '/products';

    constructor(
        private http: HttpClient,
    )
    {
    }

    get(filters: { key: string, value: any }[]): Observable<{ total_count: number, items: ProductModel[] }>
    {
        const params = GetQueryParamsService.getQueryParams(filters);

        return this.http.get<{ total_count: number, items: ProductModel[] }>(this.endPoint, {
            params,
        });
    }

    create(product: CreateProductModel): Promise<{ status: boolean, message: string, data: ProductModel }>
    {
        return firstValueFrom(this.http.post<{ status: boolean, message: string, data: ProductModel }>(this.endPoint, product));
    }

    update(product: ProductModel): Promise<{ status: boolean, message: string, data: ProductModel }>
    {
        const url = `${this.endPoint}/${product.id}`;

        return firstValueFrom(this.http.put<{ status: boolean, message: string, data: ProductModel }>(url, product));
    }

    delete(id: number): Promise<{ status: boolean, message: string }>
    {
        const url = `${this.endPoint}/${id}`;

        return firstValueFrom(this.http.delete<{ status: boolean, message: string }>(url));
    }
}
