import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';

@Injectable()
export class DomainInterceptor implements HttpInterceptor
{
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        const url = req.url;

        if (url.includes(environment.domain))
        {
            return next.handle(req.clone());
        }

        return next.handle(req.clone({
            url: `${environment.domain}${url}`,
        }));
    }
}
