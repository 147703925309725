import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';

import { CreateStoreModel, StoreModel } from '@models';
import { GetQueryParamsService } from './get-query-params.service';

@Injectable({
    providedIn: 'root'
})
export class StoreService
{
    private endPoint = '/stores';

    constructor(
        private http: HttpClient,
    )
    {
    }

    get(filters: { key: string, value: any }[]): Observable<{ total_count: number, items: StoreModel[] }>
    {
        const params = GetQueryParamsService.getQueryParams(filters);

        return this.http.get<{ total_count: number, items: StoreModel[] }>(this.endPoint, {
            params,
        });
    }

    create(store: CreateStoreModel): Promise<{ status: boolean, message: string, data: StoreModel }>
    {
        return firstValueFrom(this.http.post<{ status: boolean, message: string, data: StoreModel }>(this.endPoint, store));
    }

    update(store: StoreModel): Promise<{ status: boolean, message: string, data: StoreModel }>
    {
        const url = `${this.endPoint}/${store.id}`;

        return firstValueFrom(this.http.put<{ status: boolean, message: string, data: StoreModel }>(url, store));
    }

    delete(id: number): Promise<{ status: boolean, message: string }>
    {
        const url = `${this.endPoint}/${id}`;

        return firstValueFrom(this.http.delete<{ status: boolean, message: string }>(url));
    }
}
