import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';

@Injectable()
export class DebugInterceptor implements HttpInterceptor
{
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        if (!environment.debug)
        {
            return next.handle(req.clone());
        }

        let params = req.params;

        if (!params.has('XDEBUG_SESSION_START'))
        {
            params = params.append('XDEBUG_SESSION_START', 'PHPSTORM');
        }

        return next.handle(req.clone({
            params,
        }));
    }
}
