import { Component } from '@angular/core';
import { Subject } from 'rxjs';

import { LoaderService } from '@services';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent
{
    isLoading: Subject<boolean> = this.loaderService.isLoading;

    constructor(
        private loaderService: LoaderService,
    )
    {
    }
}
