import { Injectable } from '@angular/core';

import { UserModel } from '@models';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class StorageService
{
    private static set(key: string, data: any): void
    {
        key = environment.databaseName + '_' + key;
        data = JSON.stringify(data);
        localStorage.setItem(key, data);
    }

    private static get(key: string): any
    {
        key = environment.databaseName + '_' + key;
        let data = localStorage.getItem(key);

        if (data !== null)
        {
            data = JSON.parse(data);
        }

        return data;
    }

    static login(user: UserModel, token: string): void
    {
        StorageService.set('account', user);
        StorageService.set('token', token);
    }

    static getUserToken(): string
    {
        return StorageService.get('token');
    }

    static signOut(): void
    {
        localStorage.clear();
    }
}
